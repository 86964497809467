.Cloudflare {
  .message_body {
    flex-direction: column;
    text-align: center;
  }
}

.composit_cont {
  position: fixed;
  width: 500px;
  background-color: white;
  border-radius: 5px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);

  // padding: 20px;
  .composit_header {
    display: flex;
    justify-content: space-between;
    background-color: #f9fafd;
    padding: 8px 8px 8px 16px;
    border-radius: 5px 5px 0px 0px;
    position: relative;
    height: 42px;

    .details {
      line-height: 25px;
      color: #202845;

    }

    .close {
      color: black;
      font-size: 25px;
      cursor: pointer;
      background: #E5E8F1;
      border-radius: 4px;

      &:hover {
        background-color: #007bff;
        color: white;
        opacity: 1;
      }
    }
  }

  .composit_body {
    display: flex;
    justify-content: space-around;
    padding-top: 14px;
    height: 500px;
    overflow-y: auto;

    .smallHeaders {
      color: #8692ba;
      font-family: "Roboto-regular";
      font-size: 12px;
      line-height: 2.1;
      font-weight: 700;
      text-transform: uppercase;
    }

    ._row {
      display: flex;
      gap: 8px;
      margin-bottom: 4px;
    }
  }
}

.main_success {
  position: absolute;
  padding: 0px 10px;
  z-index: 50000;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;
  gap: 5px;

  .message_cont {
    max-width: 50%;
  }

  scrollbar-color:#8692ba #f1f1f1 ;
  scrollbar-color:hover {
    background: #2a3354;
  }
  scrollbar-width: thin;
}

.message_cont {
  position: absolute;
  width: fit-content;
  padding: 0px 10px;
  z-index: 50000;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: visible;
  gap: 5px;
  word-break: break-word;
  line-height: 20px;

  .close_all_messages {
    cursor: pointer;
    text-align: end;
    font-weight: 500;
    right: 0px;
    top: 0px;
    color: black;
    font-weight: 800;
    padding: 8px 0 0 0px;
  }

  .message_body {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    width: 100%;

    >p {
      width: 180px;
      text-align: right;
      margin-bottom: 0;
    }

    >div {
      width: fit-content;

    }

    .warn,
    .success,
    .error,
    .info {
      transition: 2s;
      right: 0;
      padding: 10px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    .warn {
      background: rgba(240, 194, 29, 0.9);
    }

    .success {
      background: rgba(96, 156, 48, 0.9);
    }

    .info {
      background: rgba(70, 149, 243, 0.9);
    }

    .error {
      background: rgba(233, 91, 91, 0.9);
      display: flex;
      align-items: center;
    }
  }


  .killer {
    margin-left: 20px;
    cursor: pointer;
  }

  .mess_icon {
    margin-right: 10px;
  }

  scrollbar-color:#8692ba #f1f1f1 ;
  scrollbar-color:hover {
    background: #2a3354;
  }
  scrollbar-width: thin;
}

.top-left {
  position: fixed;
  top: 0px;
  left: 0px;

}

.top-center {
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.top-right {
  position: fixed;
  top: 0px;
  right: 0px;
}

.bottom-left {
  position: fixed;
  bottom: 0px;
  left: 0px;
}

.bottom-center {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.bottom-right {
  position: fixed;
  bottom: 0px;
  right: 0px;
}

.socketPopUpCont {
  border-radius: 8px;
  position: fixed;
  z-index: 12000;
  top:15px;
  right:37px;

  .popUpCont {
    width: 400px;
    max-height: 300px;
    height: auto;
    background-color: #EFF2FA;
    border-radius: 8px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);

    .warn {
      background: rgba(240, 194, 29, 0.9);
    }

    .success {
      background: rgba(96, 156, 48, 0.9);
    }

    .info {
      background: rgba(70, 149, 243, 0.9);
    }

    .error {
      background: rgba(233, 91, 91, 0.9);
      display: flex;
      align-items: center;
    }

    .kyc_upload {
      color: #6A77A3;
      display: flex;
      gap: 10px;
      padding: 8px 12px 8px 10px;
      align-items: baseline;
      border-radius: 4px;

      .ikbs-break {
        color: black;
        font-size: 16px;
      }

      .greenDot {
        width: 9px;
        height: 9px;
        background-color: #76B245;
        border-radius: 10px;
      }

      .titleCont {
        padding: 0;
        height: 18px;
        color: #8692BA;
        width: 100%;
        position: relative;
      }
    }

    .close {
      position: absolute;
      top: -2px;
      right: -5px;
      color: black;
      font-size: 30px;
      cursor: pointer;
      background: #E5E8F1;
      border-radius: 4px;

      &:hover {
        background-color: #007bff;
        color: white;
        opacity: 1;
      }
    }

  }

  .titleCont {
    display: flex;
    justify-content: space-between;
    height: 40px;
    color: white;
    padding: 10px;
    border-radius: 4px 4px 0 0;

  }

  .bodyCont {
    padding: 15px;
    width: 100%;
    border-radius: 0 0 4px 4px;

    >div {
      border-radius: 0 0 4px 4px;

      margin: 0;
      line-height: 1;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      span:first-child {
        max-width: 64%;
        word-break: break-all;
      }

      span:last-child {
        font-size: 12px;
        text-align: right;
        max-width: 35%;
      }
    }
  }
}

@media screen and (max-width: 512px) {
  .main_success {
    .message_cont {
      max-width: 100%;
      width: 100%;
    }
  }
}

