.buffer{
.k-grid-toolbar   {
    align-items: baseline;
    justify-content: space-between;
    padding: 14px 5px;
  }
  .kyc_icons{
    margin-right: 10px;
    display: inline-block;
    font-size: 26px;
    cursor: pointer;
    background-size: cover;
    color:red;

  }

}

