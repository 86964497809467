import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { content, deployCont, sideMenu, versionCont } from "@worker/animations";
import { fader, faderChild, fromBounce, ikbsSidebar } from "@worker/route-animations";
import { AsyncPipe, CommonModule, NgClass, NgTemplateOutlet, PlatformLocation } from "@angular/common";
import { DrawerComponent, DrawerMode, DrawerModule, DrawerSelectEvent } from "@progress/kendo-angular-layout";
import { ActivatedRoute, ChildrenOutletContexts, Router, RouterLink, RouterOutlet } from "@angular/router";
import { AuthService } from "@services/auth.service";
import { SharedData } from "@services/sharedData.service";
import { ApiService } from "@services/api.service";
import { StoreService } from "@services/store.service";
import { SocketService } from "@services/socket.service";
import { HelpFunctions } from "@helper/help-functions";
import { AppComponentService } from "@services/app-component.service";
import { GlobalListenersService } from "@services/global-listeners.service";
import { IkdropdownComponent } from "@worker/shared/ikdropdown/ikdropdown.component";
import { MessagesComponent } from "@worker/shared/system_messages/messages.component";
import { AppRightPanelComponent } from "@worker/shared/app-right-panel/app-right-panel.component";
import { LoginComponent } from "@worker/login/login.component";
import { RowFilterModule } from "@progress/kendo-angular-grid";
import { BufferGridComponent } from "@worker/shared/buffer-grid/buffer-grid.component";
import { DialogModule } from "@progress/kendo-angular-dialog";
import { DeployComponent } from "@worker/cms/sub-components/deploy/deploy.component";
import * as bulkHelper from "./cms/form-administration/settings";
import {
  menu_item,
  menu_raw,
  panel_item,
  project_item,
  raw_panel_item,
  simple_item,
  store_project_item,
} from "./interfaces/app_component/app_interface";
import { panelStore, user } from "./interfaces/Interfaces";
import { fromEvent, Subject, takeUntil } from "rxjs";
import { environment } from "../environments/environment";
import { InternalMessageService } from "@services/internalMessage.service";
import { FormioHandlerService } from "@services/formio-handler.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [
    DrawerModule,
    NgClass,
    IkdropdownComponent,
    MessagesComponent,
    RouterLink,
    NgTemplateOutlet,
    RouterOutlet,
    CommonModule,
    AppRightPanelComponent,
    LoginComponent,
    RowFilterModule,
    BufferGridComponent,
    DialogModule,
    DeployComponent,
  ],
  providers: [AppComponentService, FormioHandlerService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [sideMenu, deployCont, versionCont, content, fader, ikbsSidebar, faderChild, fromBounce],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("menu") public menu: ElementRef<HTMLElement> | undefined;
  @ViewChild("drawer") public drawer: DrawerComponent | undefined;
  AuthService: AuthService = inject(AuthService);
  SharedData: SharedData = inject(SharedData);
  ApiService: ApiService = inject(ApiService);
  StoreService: StoreService = inject(StoreService);
  SocketService: SocketService = inject(SocketService);
  AppComponentService: AppComponentService = inject(AppComponentService);
  GlobalListenersService: GlobalListenersService = inject(GlobalListenersService);
  router: Router = inject(Router);
  cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  ActivatedRoute: ActivatedRoute = inject(ActivatedRoute);
  platformLocation: PlatformLocation = inject(PlatformLocation);
  HelpFunctions: HelpFunctions = inject(HelpFunctions)
  ElementRef: ElementRef = inject(ElementRef)
  InternalMessageService: InternalMessageService = inject(InternalMessageService)
  FormioHandlerService: FormioHandlerService = inject(FormioHandlerService)
  admin_env: string = (() => {
    if (window.location.origin.includes('crm-dev')) {
      return "dev"
    }
    if (window.location.origin.includes('crm-stage')) {
      return "stage"
    }
    // if (window.location.origin.includes('192.168')) {
    //   return "demo"
    // }
    return "prod"
  })()
  season_env: string = (() => {
    return localStorage.getItem("ikbs_season") || ""
  })()
  _menuItemLevelTwo = signal<menu_item[]>([]);
  _panelDropDownSignal = signal<panel_item[]>([]);
  _currentPanel = signal<panel_item>({
    text: "",
    value: "",
    _api: "",
    _env: [],
    _type: "",
    applied_projects: [],
    bucketData: "",
    name: "",
    panel_base: "",
    role_layout: "",
  });
  _projectlist = signal<project_item[]>([]);
  _selectedProject = signal<project_item>({
    _type: "",
    alias: "",
    host_id: 0,
    languages: [],
    name: "",
    prefix: "",
    tech: false,
    text: "",
    value: "",
  });
  _rawMenu = signal<menu_raw>({});
  public onDestroy$ = new Subject<void>();

  /* Event Subscriptions */

  public handlerKeyboard$ = this.GlobalListenersService.handlerKeyboard.pipe(takeUntil(this.onDestroy$)).subscribe((event: Event) => {
    this.AppComponentService.KeyboardEventhandler();
    this.AppComponentService.controlF(event);
  });
  public handlerSize$ = this.GlobalListenersService.handlerResize.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
    this.openCloseLeftSidebar(window.innerWidth);
  });

  public handlerMouse$ = this.GlobalListenersService.handlerMouse.pipe(takeUntil(this.onDestroy$)).subscribe((event: Event) => {
    this.AppComponentService.KeyboardEventhandler();
    this.AppComponentService.whiteBlackTheme(event.target);
    const input = event.target as HTMLElement;
    if (this._menuItemLevelTwo()?.length > 0) {
      let shoMenu = false;
      const levelTwoItem = this._menuItemLevelTwo().find((item: any) => item.title == input.textContent)
      if (levelTwoItem) shoMenu = true;
      if (this.menu?.nativeElement.contains(input)) {
        shoMenu = true;
      }
      if (input.className.indexOf("ikbs") !== -1) {
        shoMenu = true;
      }
      if (this.drawer && this.drawer["element"].nativeElement.contains(input)) {
        shoMenu = true;
      }

      if (!shoMenu) {
        this.clearDumpMenu();
      }
      // this.cdRef.markForCheck();
    }
  });

  private swipeCoord?: [number, number];
  private swipeTime?: number;
  public deployAccess: any;

  // //CMS-Form Administration
  public deployContState: string = "start";
  public versionContState: string = "start";
  public expandMode: DrawerMode = "push";
  public logoutButton: menu_item = {
    title: "Log out",
    _alias: "_logout",
    path: "/logout",
    icon: "ikbs-exit",
    selected: false,
    expanded: false,
    parent: false,
    level: 0,
    BreadScrump: [],
    parentIndex: 0,
    currentIndex: 0,
    children: [],
  };

  public AutocheckerButton: menu_item = {
    title: "Mirror Checker",
    _alias: "mirror_checker",
    path: "/crm/mirror_checker/projects",
    icon: "ikbs-warning",
    selected: false,
    expanded: false,
    parent: false,
    level: 0,
    BreadScrump: [],
    parentIndex: 0,
    currentIndex: 0,
    children: [],
  };
  // public versionData: any;
  // public deployData: any;
  public isDeployDIalogOpen: boolean = false;
  public expanded: boolean = false;
  public Managedrawerexpanded: boolean = false;
  // public selectedProject: any = {};
  // public currentPanel: any = {};
  public menuBackupState: Array<menu_item> = [];
  public breadScrumps: string = "";
  public currentLevelTwoItem: string = "";
  public panel_type = window.location.origin.indexOf("ltd-ss") != -1;
  public isFLush: boolean = false;
  public hugeType: string = "deploy";
  public env: string = "";
  public cash_list_manager: Array<simple_item> = bulkHelper.default.cash_list_manager;
  public cash_list_schema: Array<simple_item> = bulkHelper.default.cash_list_schema;
  public cash_list_client: Array<simple_item> = bulkHelper.default.cash_list_client;
  public cashSelected_managent: any;
  public formAdminPage: string = "";
  public FormAdminPageSubscribe = this.SharedData.FormAdminPage;
  public Menu = computed(() => {
    return this.initMenu(this._rawMenu(), this._panelDropDownSignal());
  });

  public _Menu = computed(() => {
    const subMenu = this.Menu()[this._currentPanel()?._api];
    if (subMenu && subMenu[this._currentPanel()?._api]) return subMenu[this._currentPanel()?._api];
    if (subMenu && subMenu[this._selectedProject()?.value]) return subMenu[this._selectedProject()?.value];
    return [];
  });

  public userAutochecker: boolean = false;

  constructor() {

  }

  ngOnInit() {
    this.GetDeployAccess()
    this.StoreService.userObserv$.pipe(takeUntil(this.onDestroy$)).subscribe((response: user) => {
      this.expanded = !!response.user?.client?.sidebar
      this.userAutochecker = response.user?.settings?.notify?.autocheker;
      let xDown: any = null
      fromEvent(this.ElementRef.nativeElement.querySelector("kendo-drawer") as HTMLElement, "touchstart").pipe(takeUntil(this.onDestroy$)).subscribe((event: any) => {
        xDown = event.touches[0].clientX;
      });
      fromEvent(this.ElementRef.nativeElement.querySelector("kendo-drawer") as HTMLElement, "touchmove").pipe(takeUntil(this.onDestroy$)).subscribe((event: any) => {
        if (!xDown) {
          return;
        }
        let xUp = event.touches[0].clientX;
        if ((xDown - xUp) > 100) {
          this.expanded = false
          this.cdRef.detectChanges();
        }
      });
      this.openCloseLeftSidebar(window.innerWidth);

    });
    this.openCloseLeftSidebar(window.innerWidth);
    this.StoreService.storeObserv$.pipe(takeUntil(this.onDestroy$)).subscribe((item: panelStore) => {
      this._panelDropDownSignal.update(() => {
        return item.panel_api.map((items: raw_panel_item) => {
          return Object.assign(items, {
            text: items["name"],
            value: items["_api"],
          });
        });
      });
      if (item.panel_name) {
        this._currentPanel.update(() => {
          return this._panelDropDownSignal().find((el: panel_item) => el._api == item.panel_name) || this._panelDropDownSignal()[0];
        });
      }
      this._projectlist.update(() => {
        return item.DD_projects.map((el: store_project_item) => {
          return Object.assign(el, {
            text: el["name"],
            value: el["alias"],
            languages: el["languages"],
          });
        });
      });
      // if ((item.DD_projects && !this._selectedProject().value) || !this.StoreService.getFromStore_Static("current_project")) {
      if (item.DD_projects) {
        this._selectedProject.update(() => {
          return this.projectSetup(item.DD_projects);
        });
      }
      if (!Object.keys(this._rawMenu()).length) {
        this._rawMenu.update(() => item.menu);
      }
    });
  }

  ngOnDestroy(): void {
    this.SocketService?.disconnect();
    this.handlerKeyboard$.unsubscribe()
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewInit() { }

  deployStateChange() {
    this.deployContState = this.deployContState === "end" ? "start" : "end";
  }
  versionStateChange() {
    this.versionContState = this.versionContState === "end" ? "start" : "end";
  }

  projectSetup(projectList: Array<project_item>) {
    let projectFromRoute: any
    if (window.location.pathname == this.StoreService.getFromSCL("route")) {
      projectFromRoute = projectList?.find((el: project_item) => window.location.pathname?.indexOf(el.alias) !== -1);
    } else {
      projectFromRoute = projectList?.find((el: project_item) => this.StoreService.getFromSCL("route")?.indexOf(el.alias) !== -1);
    }
    if (projectFromRoute) {
      return this.setCurrentProjectAndReturn({
        ...projectFromRoute,
        languages: projectFromRoute.languages || [],
      });
    }

    let parseLs: any = "";
    try {
      parseLs = JSON.parse(localStorage.getItem("project") || "{}");
    } catch (error) {
      console.log(error);
    }
    const localStorageProject = projectList?.find((el: project_item) => el.alias === parseLs["value"]);
    if (localStorageProject) {
      return this.setCurrentProjectAndReturn({
        ...localStorageProject,
        languages: localStorageProject.languages || [],
      });
    }


    if (!projectList[0]) return {
      _type: "",
      alias: "",
      host_id: 0,
      languages: [],
      name: "",
      prefix: "",
      tech: false,
      text: "",
      value: "",
    };
    const project = {
      ...projectList[0],
      languages: projectList[0].languages || [],
    };
    localStorage.setItem("project", JSON.stringify(project));
    return this.setCurrentProjectAndReturn(project);
  }

  setCurrentProjectAndReturn(project: store_project_item) {
    if (this.StoreService.getFromStore_Static("current_project")?.alias !== project?.alias && project.alias != "testcasino") {
      this.StoreService.addToStore_static("current_project", project);
    }
    return {
      text: project.name,
      value: project.alias,
      languages: project.languages,
      _type: "",
      alias: "",
      host_id: 0,
      name: "",
      prefix: "",
      tech: false,
    };
  }

  initMenu(menuWithURLS: any, panelItemsList: Array<panel_item>) {
    const result: any = {};
    panelItemsList = panelItemsList?.map((item: raw_panel_item) => {
      return { text: item.name, value: item._api, ...item };
    });
    const url2 = window.location.href.replace(/%3f/g, "?").replace(/%3d/g, "=").replace(/%3F/g, "?").replace(/%3D/g, "=");
    let lookingItem = "";
    lookingItem = this.StoreService.getFromSCL("route") ? this.StoreService.getFromSCL("route")?.toLowerCase() : new window.URL(url2).pathname;
    // console.log(menuWithURLS)
    if (panelItemsList.length) {
      panelItemsList?.forEach((el: panel_item) => {
        result[el.value] = {};
        if (el.applied_projects.length) {
          el.applied_projects.forEach((pr: string) => {
            if (menuWithURLS[el.value][pr]) {
              result[el.value][pr] = this.menuListCreation(JSON.parse(JSON.stringify(menuWithURLS[el.value][pr].en)));
              result[el.value][pr] = this.addbreadScrump(result[el.value][pr], lookingItem);
              if (!result[el.value][pr].filter((el: any) => el._alias == "_logout").length) result[el.value][pr].push(this.logoutButton);
            }
          });
        } else {
          result[el.value][el.value] = this.menuListCreation(JSON.parse(JSON.stringify(menuWithURLS[el.value][el.value].en)));
          result[el.value][el.value] = this.addbreadScrump(result[el.value][el.value], lookingItem);
          if (!result[el.value][el.value].filter((el: any) => el._alias == "_logout").length) {
            if (this.userAutochecker && el.value == "crm") result[el.value][el.value].push(this.AutocheckerButton);
            result[el.value][el.value].push(this.logoutButton);
          }
        }
      });
    }
    return result;
  }

  menuListCreation(rawMenu: Array<menu_item>, result: Array<any> = [], level: number = 0, pIndex: number | null = null) {
    rawMenu.forEach((menu: any, i: number) => {
      const { text, _alias, router_link, icon, items } = menu;
      result[i] = {
        title: text,
        _alias,
        path: router_link,
        icon: this.tempMenuAdjust(icon),
        selected: false,
        expanded: false,
        parent: Array.isArray(items) && items.length > 0,
        level,
        parentIndex: pIndex !== null ? pIndex : i,
        currentIndex: i,
      };
      if (Array.isArray(items) && items.length > 0) {
        const conter = level === 1 ? 2 : 1;
        result[i].children = this.menuListCreation(items, [], conter, pIndex !== null ? pIndex : i);
      }
    });
    return result;
  }

  addbreadScrump(menuItems: Array<menu_item>, lookingItem: string) {
    this.findTitle(menuItems, lookingItem);
    return menuItems;
  }

  findTitle(array: Array<menu_item>, lookingItem: string, breadscrump: Array<string> = []) {
    for (let i = 0; i < array.length; i++) {
      array[i]["BreadScrump"] = breadscrump.concat(array[i].title);

      if (array[i].path && (("/" + array[i].path.toLowerCase()).includes(lookingItem.toLowerCase()))) {
        this.currentLevelTwoItem = array[i]["BreadScrump"][0];
        this.breadScrumps = array[i]["BreadScrump"].slice(1).join(" / ");
      }
      if (Array.isArray(array[i].children) && array[i].children.length > 0) {
        this.findTitle(array[i].children, lookingItem, array[i]["BreadScrump"]);
      }
    }
  }

  currentPanelChange(item: panel_item) {
    this._currentPanel.update(() => item);
  }

  /*Project Handler*/
  projectselect(event: project_item) {
    if (event) {
      this.StoreService.addToStore_static("current_project", event);
      this._selectedProject.update(() => event);
      localStorage.setItem("project", JSON.stringify(this._selectedProject()));
      const url = new URL(window.location.href);
      const fart = url.pathname.split("/");
      fart[3] = event["value"];
      url.pathname = fart.join().replace(/,/g, "/");
      this.router.navigate([`${url.pathname}`]);
    }
  }

  //Switching panels
  DashboardSelect(item: panel_item) {
    switch (item._api) {
      case 'crm':
        this.router.navigate([this.setRoute(this.Menu()['crm']['crm'])]);
        break;
      case 'cms':
        if (!this._selectedProject().value) {
          this.router.navigate([this.setRoute(this.Menu()['cms'][this.StoreService.getFromStore_Static("DD_projects")[0].alias])]);
        } else {
          this.router.navigate([this.setRoute(this.Menu()['cms'][this._selectedProject().value])]);
        }
        break;
      case 'core':
        this.router.navigate([this.setRoute(this.Menu()['core']['core'])]);
        break;
      case 'administration_system':
        this.router.navigate([this.setRoute(this.Menu()['administration_system']['administration_system'])]);
        break;
      case 'modules':
        this.router.navigate([this.setRoute(this.Menu()['modules']['modules'])]);
        break;

      default:
        break;
    }

  }

  setRoute(pathArray: any) {
    const path = pathArray.find((el: any) => el.path || (el.children.length && el.children[0].path))

    return path.path || (path.children.length && path.children[0].path)

  }

  tempMenuAdjust(icon: string) {
    if (!icon) {
      icon = "ikbs-close";
      return icon;
    }
    if (icon.indexOf("ik-") != -1) {
      const x = icon.split("-")[1];
      icon = "ikbs-" + x;
    }
    if (icon.indexOf("fa-") != -1) {
      icon = "ikbs-close";
    }
    return icon;
  }

  selectMenu(ev: DrawerSelectEvent, click: boolean): void {
    ev.item.selected = true;

    if (ev.item.path) {
      this.breadScrumps = ev.item["BreadScrump"].slice(1).join("/");
    } else {
      // if (this.currentLevelTwoItem == ev.item["BreadScrump"][0]) {
      //   this.breadScrumps = this.breadScrumps;
      // } else {
      //   // this.breadScrumps = ev.item['BreadScrump'].slice(1).join('/')
      // }
    }
    // this.breadScrumps =ev.item.path? ev.item['BreadScrump'].slice(1).join('/'):this.currentLevelTwoItem ==ev.item['BreadScrump'][0]?this.breadScrumps:ev.item['BreadScrump'].slice(1).join('/')
    if (ev.item.path) {
      this.currentLevelTwoItem = ev.item["BreadScrump"][0];
    }
    if (!ev.item) ev["item"] = ev;
    if (ev.item?._alias == "_logout" && click) {
      // ev.item._alias == "_logout" ? this.logout() : "";
      return;
    }

    if (ev.item.children?.length > 0 && this.menuBackupState !== ev.item.children) {
      this._menuItemLevelTwo.update(() => JSON.parse(JSON.stringify(ev.item.children)));

      if (window.innerWidth < 640) {
        this.expanded = false;
      }
      this.menuBackupState = ev.item.children;
      return;
    }
    if (window.innerWidth < 640) {
      this.expanded = false;
    }
    this.menuBackupState = [];
    this._menuItemLevelTwo.update(() => []);
  }

  clearDumpMenu() {
    this._menuItemLevelTwo.update(() => []);
    this.menuBackupState = [];

    // this.cdRef.markForCheck();
  }

  menuSelectHot(item: menu_item) {
    if (item.path) {
      this.currentLevelTwoItem = item["BreadScrump"][0];
      this.breadScrumps = item["BreadScrump"].slice(1).join(" / ");
      this.router.navigate([item.path]);
      this.clearDumpMenu();
      return;
    }

    if (item.level == 1) {
      if (item.selected) {
        const newItems = this.resetItems(this.menuBackupState);
        this._menuItemLevelTwo.update(() => newItems);
        return
      }
      const newItems = this.resetItems(this.menuBackupState);
      if (item.children?.length > 0) {
        for (const items of newItems) {
          if (items.title == item.title) {
            items.selected = true;
            if (!items.expanded) {
              items.expanded = true;
              newItems.splice(item.currentIndex + 1, 0, ...item.children);
            }
          }
        }
      }
      this._menuItemLevelTwo.update(() => newItems);
    }
    if (item.level == 2) {
      for (let i = 0; i < this._menuItemLevelTwo().length; i++) {
        if (this._menuItemLevelTwo()[i].title == item.title) {
          const newItems = this.resetItems([this._menuItemLevelTwo()[i]]);
          if (item.children?.length > 0) {
            for (const items of newItems) {
              if (items.title == item.title) {
                items.selected = true;
                if (!items.expanded) {
                  items.expanded = true;
                  newItems.splice(item.currentIndex + 1, 0, ...item.children);
                }
              }
            }
          }
          newItems.forEach((el) => {
            if (!el.parent) {
              el.level = 3;
            }
          });
          this._menuItemLevelTwo.update((current: Array<menu_item>) => {
            current.splice(i, 1);
            current.splice(i, 0, ...newItems);
            return current;
          });

          break;
        }
      }
    }
  }

  resetItems(items: Array<menu_item>): Array<menu_item> {
    const arr: Array<menu_item> = [];
    items.forEach((item: menu_item) => {
      arr.push(Object.assign({}, item));
    });
    return arr;
  }

  /*LOgout*/
  logout() {
    if (this.AuthService.isAuthenticated()) {
      this.ApiService.Getmethod("account/logout").pipe(takeUntil(this.onDestroy$)).subscribe(
        () => {
          this.AuthService.logout();
          this.SocketService.remove_all_account_update();
          this.SocketService.disconnect();
          this.router.navigate(["logout"]);
        },
        () => {
          this.AuthService.logout();
          this.SocketService.remove_all_account_update();
          this.SocketService.disconnect();
          this.router.navigate(["logout"]);
          window.location.reload()
        }
      );
    } else {
      this.AuthService.logout();
      this.SocketService.remove_all_account_update();
      this.SocketService.disconnect();
      this.router.navigate(["logout"]);
      window.location.reload()
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData["animation"];
  }

  leftToolBarExpandChanges(event: boolean) {
    this.GlobalListenersService.leftsidebar.next(event);
    this.expanded = event;
    console.log(this.expanded)
    const payload = { path: "sidebar", data: event };
    this.ApiService.Putmethod("account/client", payload).pipe(takeUntil(this.onDestroy$)).subscribe();
    this.cdRef.detectChanges();
  }

  openCloseLeftSidebar(width: number) {
    if (width < 1024) {
      this.expanded = false;
      this.GlobalListenersService.leftsidebar.next(false);

      this.cdRef.detectChanges();
      return;
    }
    this.GlobalListenersService.leftsidebar.next(true);
    // this.expanded = true;
    this.cdRef.detectChanges();
  }

  leftSideBarOpenClose() {
    console.log('debil')
    if (window.innerWidth < 1024) {
      this._menuItemLevelTwo.update(() => []);
    }
    this.drawer?.toggle();
  }

  rightToolBarDeployCashProcess(env: string, type: string, event: any) {
    if (this.isFLush) {
      const dataToSend = {
        types: ["flushCashBucket"],
      };
      this.ApiService.Postmethod(`cash/client/${this.StoreService.getFromStore_Static("current_project")?.alias}/${event}`, dataToSend).pipe(takeUntil(this.onDestroy$)).subscribe(
        (response) => {
          console.log(response);
        }
      );
      return;
    }
    if (event?.shiftKey) type = "forceClearCache"
    this.isDeployDIalogOpen = !this.isDeployDIalogOpen;
    this.hugeType = type;
    this.env = env;
  }

  copyInputMessage() {
    const token: string = this.AuthService.getToken() || "";
    navigator.clipboard
      .writeText(token)
      .then(() => this.InternalMessageService.successMessage(`Token is copied`))
      .catch((e) => console.error(e));
  }
  cashselect(event: simple_item) {
    if (event.value === "none") return;
    if (this.formAdminPage) {
      this.HelpFunctions.cashOfActionSwitcher[event.value]("data", this.formAdminPage, this._selectedProject().value);
      return;
    }
    this.HelpFunctions.cashOfActionSwitcher[event.value]("schema", "", this._selectedProject().value);
  }

  GetDeployAccess() {
    this.SharedData.deploy_Access.pipe(takeUntil(this.onDestroy$)).subscribe((deployAccess) => {
      this.deployAccess = this.StoreService.getUser().settings.role.includes('superadmin') ? { access: true, option: true, deploy_prod: true, deploy_dev: true } : deployAccess;
    });
  }
  // CashHandler() {
  //   this.cashSelected_managent = this.cash_list_manager[0];
  //   this.FormAdminPageSubscribe.subscribe((response: any) => {
  //     this.formAdminPage = response.type;
  //     // this.selectedProject = this.listMenuItems.filter(el => el['value'] == response.project)[0]
  //     // if (this.formAdminPage) {
  //     //   this.cash_list_manager = bulkHelper.default.cash_list_manager
  //     //   this.cashSelected_managent = this.cash_list_manager[0];
  //     // } else {
  //     //   this.cash_list_manager = bulkHelper.default.cash_list_schema
  //     //   this.cashSelected_managent = this.cash_list_schema[0];
  //     // }
  //   });
  // }
}
